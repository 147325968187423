<template>
  <div class="cp_collect_list_wrap">

    <!--리스트 형태-->
    <div class="cp_collect_list_box" v-for="i in 5" :key="i" v-show="listType">
      <div class="cp_collect_img_box"></div>
      <div class="cp_collect_info_box">
        <div class="cp_collect_info top">
          <div class="cp_collect_info_title_box">
            <div class="cp_collect_info_title">
              <div class="cp_collect_badge">
                <span>{{ $t('en_art') }}</span>
              </div>
              <div class="cp_collect_title">병원로고 디자인 의뢰</div>
            </div>
            <div class="cp_collect_info_heart">
              <div class="heart_img"></div>
              <div class="heart_count">251</div>
              <div class="eyes_img"></div>
              <div class="eyes_count">1,251</div>
              <div class="cp_status">{{ $t('scheduled_to_proceed') }}</div>
            </div>
          </div>
          <div class="cp_collect_hashtag">
            <span>#해시태그</span>
            <span>#태그</span>
          </div>
          <div class="cp_collect_thumbnail_box">
            <div class="cp_collect_thumbnail"></div>
            <div class="cp_collect_nick">Nickname</div>
          </div>
        </div>
        <div class="cp_collect_info bottom">
          <div class="cp_collect_info_request_box">
            <div class="cp_collect_info_request_text">{{ $t('entry') }}</div>
            <div class="cp_collect_info_request_date">
              <span>2021.09.04 ~ 21.11.23</span>
            </div>
          </div>
          <div class="cp_collect_info_request_box">
            <div class="cp_collect_info_request_text">{{ $t('presentation') }}</div>
            <div class="cp_collect_info_request_date">
              <span>2021.12.24</span>
            </div>
          </div>
          <div class="cp_collect_info_request_box">
            <div class="cp_collect_info_request_text">{{ $t('t_prize') }}</div>
            <div class="cp_collect_info_request_date">
              <div class="mingle_small_logo"></div>
              <span class="mingle_price">10,000,000Mg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--썸네일 형태-->
    <div class="cp_collect_thumb_list_box" v-show="!listType">
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>14:35:53</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge active">
            <span>{{ $t('p_completed') }}</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>14:35:53</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge">
            <span>D-12</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
      <div class="cp_collect_thumb_list">
        <div class="cp_collect_thumb_img">
          <div class="cp_collect_thumb_badge active">
            <span>{{ $t('p_completed') }}</span>
          </div>
        </div>
        <div class="cp_collect_thumb_type">
          <span>{{ $t('en_art') }}</span>
        </div>
        <div class="cp_collect_thumb_title">
          <span>병원로고 디자인 의뢰</span>
        </div>
      </div>
    </div>
    <!--    <button @click="moveDetail(`${idx}`)">상세 페이지</button>-->
  </div>
</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
  name: "CompetitionListArtLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      idx: 1,
      listType: false,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    EventBus.$on('changeListType', this.changeListType);
  },
  beforeDestroy() {
    EventBus.$off('changeListType');
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(data){
      this.$router.push(`/competition/${data}`)
    },
    changeListType(){
      this.listType = !this.listType;
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>