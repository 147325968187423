import { render, staticRenderFns } from "./CompetitionListArtLayout.vue?vue&type=template&id=72142773&scoped=true"
import script from "./CompetitionListArtLayout.vue?vue&type=script&lang=js"
export * from "./CompetitionListArtLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72142773",
  null
  
)

export default component.exports